



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {User} from '@/model/resource/User'
import {InputUserSchema} from '@/schema/resource/User/InputUserSchema'

@Component
export default class PersistUserView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputUserSchema()
  user = new User()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.user.getUser(id)
    }

    this.$await.done('getUser')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.user.persistUser()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/user/list')
  }
}
